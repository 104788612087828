import BaseUiButton from '@dns-modules/base-ui-button/base-ui-button.vue';

/**
 * Компонент меню
 */
export default {
	name: 'base-menu',
	components: {
		BaseUiButton,
	},
	props:      {
		/** Находится ли меню в подвале сайта */
		isFooter: {type: Boolean, default: false},
		/** Является ли меню мобильным */
		isMobile: {type: Boolean, default: false},
	},
	setup() {
		/** Полный список элементов меню */
		const menuList = [
			{title: 'Главная', link: '', icon: 'home'},
			{title: 'Работа', link: 'work', icon: 'work'},
			{title: 'Курсы 1С', link: 'one-c', icon: 'school'},
			{title: 'Культура', link: 'culture', icon: 'green'},
			{title: 'О нас', link: 'about-us', icon: 'about'}
		];

		/** Отображается ли модал ОС */
		const isShowModal = ref(false);

		/** Получить список элементов меню без главной страницы */
		const menuListSliced = menuList.slice(1);

		/** Отрисовка модала */
		function showModal() {
			isShowModal.value = true;
		}

		/** Обработчик открытия модала ОС */
		const closeModalHandler = () => {
			isShowModal.value = false;
		}

		return {
			menuList,
			menuListSliced,
			isShowModal,
			closeModalHandler,
			showModal,
		}
	}
}
