<script setup lang="ts">
</script>

<template>
	<div class="wrapper" id="start">
		<div class="skip skip_top"><a href="#main">Перейти к основному содержимому</a></div>
		<BaseHeader/>
		<main class="content" id="main">
			<slot/>
		</main>
		<div class="skip skip_bottom"><a href="#start">Перейти в начало страницы</a></div>
		<BaseFooter/>
	</div>
</template>

<style lang="scss">
.wrapper {
	display:        flex;
	flex-direction: column;
	min-height:     100vh;
	position:       relative;
}

p, div, span, a {
	font-family: Calibri, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	font-family: RobotoSlab-Bold, sans-serif;
}

.content {
	flex: 1;
}

.skip {
	position: absolute;
	left:     2px;
	z-index:  1000;

	&_top {
		top: 2px;
	}

	&_bottom {
		bottom: 64px;
	}
}

.skip a {
	display:         block;
	position:        absolute;
	left:            -9999px;
	width:           fit-content;
	white-space:     nowrap;
	font-weight:     600;
	text-decoration: none;
	background:      #fc8507;
	color:           white;
	border-radius:   10px;
	padding:         20px 30px;

	&:focus {
		left: 0;
		top:  0;
	}
}
</style>
