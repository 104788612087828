<script lang="ts" src="./index.ts"></script>

<template>
	<div
		ref="header"
		:class="[
			'header',
		]"
	>
		<div class="container">
			<div class="header-block">
				<div
					class="header-block__back"
					@click="comeBack"
					v-if="!isHomePage"
				/>
				<div class="header__logo logo">
					<NuxtLink class="logo__link" to="/">
						<img src="/images/dns-it-logo.svg" alt="DNS технологии - ссылка на главную страницу." class="logo__img">
					</NuxtLink>
				</div>
				<BaseMenu />
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import "../../assets/styles/variables/variables";
@import "../../assets/styles/dns-it-icons";
@import "../../assets/styles/variables/colors";

.header {
	z-index:       20;
	position:      fixed;
	padding:       24px 0;
	height:        73px;
	width:         100%;
	background:    rgba(0, 0, 0, 0);
	border-bottom: 0;
	transition:    ease .3s;

	&_untransparent {
		background: rgba(255, 255, 255, .95) !important;
	}
}

.header-block {
	display:         flex;
	justify-content: space-between;

	&__back {
		@extend %dit-arrow-right;
		margin:     0;
		position:   absolute;
		left:       2%;
		top:        10%;
		cursor:     pointer;
		transform:  rotate(180deg);
		padding:    12px;
		width:      45px;
		height:     45px;
		box-sizing: border-box;
		transition: .5s;
		font-size:  21px;
		display:    none;
	}
}

.logo {
	width:  230px;
	height: 25px;

	&__link {
		display: block;
		width:   100%;
		height:  100%;
	}

	&__img {
		width:  100%;
		height: 100%;
	}
}

@include respond-to(phone) {
	.header-test {
		padding: 20px 0;
		height:  58px;

		&__logo-img {
			width:  165px;
			height: 18px;
		}
	}

	.header-block {
		justify-content: center;

		&__back {
			display: block;
		}
	}

	.logo {
		width:  165px;
		height: 18px;
	}
}
</style>
