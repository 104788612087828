<script lang="ts" src="./index.ts"></script>

<template>
	<div :class="['menu', {'menu_mobile': isMobile}]">
		<nav class="menu__nav menu__nav_mobile">
			<template v-if="isMobile">
				<ul class="menu__list menu__list_mobile">
					<li
						:class="['menu__item', 'menu__item_mobile']"
						v-for="item in menuList"
					>
						<NuxtLink
							:class="['menu__link', 'menu__link_mobile']"
							exact
							active-class="menu__link_active"
							:to="'/' + item.link" v-bind:key="item.link"
						>
							<div :class="['menu__icon', 'menu__' + item.icon + '-icon']"></div>
							<span class="menu__title">{{ item.title }}</span>
						</NuxtLink>
					</li>
				</ul>
			</template>
			<template v-else>
				<ul class="menu__list">
					<li
						v-for="(item, index) in menuListSliced"
						:key="index"
						:class="[
							'menu__item',
							{'menu__item_mini' : isFooter}
						]"
					>
						<template v-if="isFooter">
							<NuxtLink
								:class="['menu__link', 'menu__link_light']"
								:to="'/' + item.link" v-bind:key="item.link"
							>
								{{ item.title }}
							</NuxtLink>
						</template>
						<template v-else>
							<NuxtLink
								class="menu__link"
								exact
								active-class="menu__link_active"
								:to="'/' + item.link" v-bind:key="item.link"
							>
								{{ item.title }}
							</NuxtLink>
						</template>
					</li>
				</ul>
				<BaseUiButton
					v-if="!isFooter"
					class="menu__feedback-button"
					title="Напишите нам"
					:size="'SMALL'"
					@clickBtn="showModal()"
					@keydown.enter="showModal(true)"
				/>
			</template>
		</nav>
		<FeedbackModal
			v-if="isShowModal"
			:has-re-captcha="true"
			@close-modal="closeModalHandler"
		/>
	</div>
</template>

<style lang="scss">
@import "../../assets/styles/variables/variables";
@import "../../assets/styles/dns-it-icons";

.menu {
	display:         flex;
	justify-content: center;

	&__nav {
		display:     flex;
		align-items: center;

		&_mobile {
			width: 100%;
		}
	}

	&__list {
		display:    flex;
		margin:     0 auto 0 10px;
		padding:    0;
		list-style: none;

		&_mobile {
			margin:          0;
			justify-content: space-evenly;
		}
	}

	&__item {
		padding-left:   40px;
		font-size:      18px;
		line-height:    22px;
		letter-spacing: -0.4px;
		flex-shrink:    0;

		&:first-child {
			padding-left: 0;
		}

		&_mini {
			padding-left: 31px;
			font-size:    16px;
		}

		&_mobile {
			padding:     0;
			width:       25%;
			text-align:  center;
			font-size:   14px;
			line-height: 16px;
			color:       #8a8a8a;
			flex-shrink: 1;

			&:hover {
				.menu__link {
					color: #fc8507;
				}

				.menu__icon {
					color: #fc8507;
				}
			}
		}
	}

	&__title {
		text-align:  center;
		font-size:   14px;
		line-height: 16px;
		color:       #8a8a8a;
	}

	&__link {
		text-decoration: none;
		color:           #333;
		transition:      color ease .3s;

		&:hover {
			color: #fc8507;
		}

		&_light {
			color: #fff;
		}

		&_mobile {
			display:     block;
			padding:     6px 0 6px 0;
			height:      100%;
			font-size:   12px;
			line-height: 16px;
			color:       #8a8a8a;
			transition:  color ease .3s;

			&:hover {
				.menu__icon {
					filter: invert(66%) sepia(29%) saturate(7328%) hue-rotate(358deg) brightness(101%) contrast(98%);
				}
			}
		}

		&_active {
			color: #fc8507;

			.menu__icon {
				filter: invert(66%) sepia(29%) saturate(7328%) hue-rotate(358deg) brightness(101%) contrast(98%);
			}

			.menu__title {
				color: #fc8507;
			}
		}
	}

	&__icon {
		font-size: 20px;
		filter:    invert(56%) sepia(0%) saturate(64%) hue-rotate(187deg) brightness(125%) contrast(92%);
	}

	&__home-icon {
		background: url('/icons/menu-icon/home.svg') center no-repeat;
		height:     24px;
	}

	&__school-icon {
		background: url('/icons/menu-icon/1C.svg') center no-repeat;
		height:     24px;
	}

	&__work-icon {
		background: url('/icons/menu-icon/find-job.svg') center no-repeat;
		height:     24px;
	}

	&__about-icon {
		background: url('/icons/menu-icon/info-circle.svg') center no-repeat;
		height:     24px;
	}

	&__green-icon {
		background: url('/icons/menu-icon/team-culture.svg') center no-repeat;
		height:     24px;
	}

	&_mobile {
		display:    none;
		width:      100%;
		height:     52px;
		flex-wrap:  nowrap;
		background: #fff;
		filter:     drop-shadow(1px -4px 4px rgba(118, 118, 118, .08));
	}

	&__feedback-button {
		margin-left: 40px;

		& > * {
			font-size:   16px;
			line-height: 20px;
			text-align:  center;
			color:       #fff;
		}
	}
}

@include respond-to(phone) {
	.menu {
		display: none;

		&_mobile {
			z-index:  1000;
			display:  flex;
			position: fixed;
			bottom:   0;
			left:     0;
		}

		&__nav {
			display: block;
		}
	}
}
</style>
