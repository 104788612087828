/**
 * Компонент шапки.
 */
export default {
	name: 'base-header',
	setup() {
		const route = useRoute();
		const router = useRouter();

		/** Является ли страница главной */
		const isHomePage = computed(() => ('index' === route.name));

		/** Ссылка на шапку */
		const header = ref<HTMLElement>();

		/** {inheritdoc} */
		onMounted(() => {
			window.addEventListener('scroll', onScroll);
		})

		/** {inheritdoc} */
		onUpdated(() => {
			window.addEventListener('scroll', onScroll);
		});

		/** Смена прозрачности при скролле. */
		const onScroll = () => {
			if (header.value) {
				header.value.style.backgroundColor = 'rgba(255, 255, 255, .95)';

				if (window.pageYOffset == 0) {
					header.value.style.backgroundColor = 'rgba(255, 255, 255, .0)';
				}
			}
		}

		/** Возврат назад */
		function comeBack() {
			router.go(-1);
		}

		return {
			header,
			isHomePage,
			comeBack,
		}
	}
}
