<script lang="ts" setup>
import useIsMobile from '~/composables/useIsMobile';

/** Текущий год */
const year = new Date().getFullYear().toString();

const {isMobile} = useIsMobile();
</script>

<template>
	<footer class="footer">
		Footer
		<div class="container">
			<div class="footer__panel">
				<BaseMenu :is-footer="true" :is-mobile="isMobile"/>
				<div class="footer__text">© {{ year }} ООО "ДНС Технологии"</div>
				<div class="footer__socials">
					<Socials :is-footer="true"></Socials>
				</div>
			</div>
		</div>
		<BaseMenu :is-mobile="true"/>
	</footer>
</template>

<style lang="scss">
@import "assets/styles/variables/variables";

.footer {
	position:   relative;
	width:      100%;
	background: #333;

	&__panel {
		display:         flex;
		padding:         24px 0;
		width:           100%;
		height:          67px;
		justify-content: space-between;
		box-shadow:      0 0 42px rgba(0, 0, 0, .08);

		& a {
			font-size: 16px;
		}
	}

	&__text {
		font-size:      16px;
		line-height:    20px;
		letter-spacing: 0.15px;
		color:          #afafaf;
	}
}

@include respond-to(phone) {
	.footer {
		&__panel {
			margin-bottom:   54px;
			padding:         12px 0;
			height:          76px;
			justify-content: center;
			flex-direction:  column-reverse;
			align-items:     center;
		}

		&__socials {
			margin-bottom: 10px;
		}
	}
}
</style>
